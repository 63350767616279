var JalisLoader,
  hasProp = {}.hasOwnProperty;

import {
  extraErrorDataIntegration,
  contextLinesIntegration
} from '@sentry/integrations';

JalisLoader = (function() {
  class JalisLoader {
    //################
    // Class methods #
    //################
    static load_theme() {
      JalisLoader.log_start();
      JalisLoader.init_sentry();
      JalisLoader.load_jalis();
      JalisLoader.fix_welcome_page();
      JalisLoader.load_map();
      JalisLoader.load_fancybox();
      JalisLoader.call_google_analytics();
      JalisLoader.call_google_tags();
      return JalisLoader.log_end();
    }

    static log_start() {
      return this.debug(`start loading page ${window.location.pathname}`);
    }

    static log_end() {
      return this.debug(`end loading page ${window.location.pathname}`);
    }

    static init_sentry() {
      if (JalisLoader.sentry_loaded === true) {
        return this.debug(" * sentry is already loaded");
      } else {
        this.debug(" * loading sentry");
        window.Sentry.init({
          dsn: $('#sentry-data').data('dsn'),
          release: $('#sentry-data').data('release'),
          environment: $('#sentry-data').data('environment'),
          ignoreErrors: $('#sentry-data').data('ignore-errors'),
          denyUrls: $('#sentry-data').data('deny-urls').map(function(url) {
            return new RegExp(url, 'i');
          }),
          tracesSampleRate: +($('#sentry-data').data('traces-sample-rate')),
          replaysSessionSampleRate: +($('#sentry-data').data('replays-session-sample-rate')),
          replaysOnErrorSampleRate: +($('#sentry-data').data('replays-on-error-sample-rate')),
          integrations: [window.Sentry.browserTracingIntegration(), window.Sentry.replayIntegration(), extraErrorDataIntegration(), contextLinesIntegration()]
        });
        return JalisLoader.sentry_loaded = true;
      }
    }

    static load_jalis() {
      var framework;
      this.debug(" * loading load_jalis");
      // Load Jalis framework
      framework = $.jgo;
      if (framework != null) {
        $('body').addClass('ready');
        framework.init();
        $('body').addClass('loaded');
        return this.debug("  Jalis framework loaded");
      } else {
        return this.debug("  Jalis framework not found");
      }
    }

    static fix_welcome_page() {
      var broadcast_height, broadcast_vheight, intro_height, intro_vheight, window_height;
      this.debug(" * loading fix_welcome_page");
      // formulas from https://stackoverflow.com/a/64983387
      // px in vh:
      // 100 * px / windowHeight
      window_height = $(window).height();
      broadcast_height = $('#broadcast-messages').height();
      broadcast_vheight = (100 * broadcast_height) / window_height;
      this.debug(`broadcast_height: ${broadcast_height}`);
      this.debug(`broadcast_vheight: ${broadcast_vheight}`);
      // vh in px:
      // windowHeight * vh / 100
      intro_vheight = 100 - broadcast_vheight;
      intro_height = (window_height * intro_vheight) / 100;
      this.debug(`intro_height: ${intro_height}`);
      this.debug(`intro_vheight: ${intro_vheight}`);
      return $('#intro').css('height', intro_height);
    }

    static call_google_analytics() {
      this.debug(" * loading call_google_analytics");
      if (typeof ga !== "undefined" && ga !== null) {
        ga('send', 'pageview', window.location.pathname);
        return this.debug("  GA called");
      } else {
        return this.debug("  GA not found");
      }
    }

    static call_google_tags() {
      this.debug(" * loading call_google_tags");
      if (typeof dataLayer !== "undefined" && dataLayer !== null) {
        dataLayer.push({
          'event': 'pageView',
          'virtualUrl': window.location.href
        });
        return this.debug("  GT called");
      } else {
        return this.debug("  GT not found");
      }
    }

    static load_map() {
      this.debug(" * loading load_map");
      return $('[data-toggle=map]').each(function() {
        var markers;
        markers = $(this).data('markers');
        return JalisLoader._create_geo_map(this, markers);
      });
    }

    static load_fancybox() {
      this.debug(" * loading load_fancybox");
      window.Fancybox.openers = new Map();
      return window.Fancybox.bind('[data-fancybox]', {
        Images: {
          protected: true
        }
      });
    }

    static _geop_map_options() {
      return {
        projection: 'Mercator',
        maxZoomLevel: 1,
        responsive: {
          enabled: true
        },
        series: [
          {
            type: 'MapPolygonSeries',
            geodata: window.AmMap.maps['fr'],
            useGeodata: true,
            mapPolygons: {
              tooltipText: '{name}',
              fill: '#67b7dc',
              states: {
                hover: {
                  properties: {
                    fill: '#3c5bdc'
                  }
                }
              }
            }
          },
          {
            type: 'MapPolygonSeries',
            geodata: window.AmMap.maps['es'],
            include: ['ES-PV',
          'ES-NC',
          'ES-AR',
          'ES-CT'],
            mapPolygons: {
              tooltipText: '{name}',
              fill: '#67b7dc',
              states: {
                hover: {
                  properties: {
                    fill: '#3c5bdc'
                  }
                }
              }
            }
          },
          {
            type: 'MapPolygonSeries',
            geodata: window.AmMap.maps['be'],
            mapPolygons: {
              tooltipText: '{name}',
              fill: '#67b7dc',
              states: {
                hover: {
                  properties: {
                    fill: '#3c5bdc'
                  }
                }
              }
            }
          },
          {
            // {
            //   type: 'MapPolygonSeries'
            //   geodata: window.AmMap.maps['pt']
            //   exclude: ['PT-20', 'PT-30']
            //   mapPolygons:
            //     tooltipText: '{name}'
            //     fill: '#67b7dc'
            //     states:
            //       hover:
            //         properties:
            //           fill: '#3c5bdc'
            // },
            type: 'MapImageSeries',
            tooltip: {
              getFillFromObject: false,
              background: {
                fill: '#FFF'
              },
              keepTargetHover: true,
              label: {
                interactionsEnabled: true
              }
            },
            mapImages: {
              children: [
                {
                  type: 'Image',
                  href: JalisLoader.map_logo,
                  width: 80,
                  height: 80,
                  horizontalCenter: 'middle'
                }
              ],
              propertyFields: {
                latitude: 'latitude',
                longitude: 'longitude'
              }
            }
          }
        ]
      };
    }

    static _create_geo_map(element, markers) {
      var config, map;
      // Get map options
      config = JalisLoader._geop_map_options();
      // Add our markers
      config['series'][3]['data'] = markers;
      // Catch click event to open popup
      config['series'][3]['mapImages']['events'] = {
        hit: function(ev) {
          map.closeAllPopups();
          map.openPopup(ev.target.dataItem.dataContext.description, ev.target.dataItem.dataContext.title);
          return $('.ampopup-content').css('background', 'rgba(255, 255, 255, 1) none repeat scroll 0% 0%');
        }
      };
      // Create map
      map = window.AmMap.am4core.createFromConfig(config, element, window.AmMap.am4maps.MapChart);
      return JalisLoader.map = map;
    }

    static debug(message) {
      return this._debug(`[ConcertoFrance]: ${message}`);
    }

    static _debug(message) {
      var debug;
      debug = $('body').data('js-debug');
      if (debug) {
        return console.debug(message);
      }
    }

    static update_url(hash) {
      var key, params, value;
      params = new URLSearchParams(location.search);
      for (key in hash) {
        if (!hasProp.call(hash, key)) continue;
        value = hash[key];
        (function(key, value) {
          if (value == null) {
            value = '';
          }
          return params.set(key, value);
        })(key, value);
      }
      return window.history.replaceState({}, '', `${location.pathname}?${params}`);
    }

  };

  //###################
  // Class attributes #
  //###################
  JalisLoader.map = false;

  JalisLoader.map_logo = null;

  JalisLoader.sentry_loaded = false;

  JalisLoader.mapping = {
    fr: 0,
    es: 1,
    be: 2
  };

  JalisLoader.zones = {
    fr: ['FR-PAC', 'FR-PDL', 'FR-OCC', 'FR-NAQ', 'FR-NOR', 'FR-IDF', 'FR-HDF', 'FR-GES', 'FR-COR', 'FR-CVL', 'FR-BRE', 'FR-BFC', 'FR-ARA'],
    es: ['ES-PV', 'ES-NC', 'ES-AR', 'ES-CT'],
    be: ['BE-VAN', 'BE-WBR', 'BE-BRU', 'BE-WHT', 'BE-WLG', 'BE-VLI', 'BE-WLX', 'BE-VOV', 'BE-WNA', 'BE-VBR', 'BE-VWV']
  };

  return JalisLoader;

}).call(this);

export default JalisLoader;

$(document).on('turbo:load', JalisLoader.load_theme);

// Autoload modal boxes
$(document).on('click', '.modal-box', function(event) {
  var url;
  event.preventDefault();
  url = $(this).attr('href');
  return $.get(url, function(data) {
    $('#modal-holder').html(data);
    return $('#modal-holder').dialog({
      draggable: false,
      resizable: false,
      closeText: ''
    });
  });
});

// Auto-submit search form
$(document).on('change', '#search-form .auto-submit', function(event) {
  event.preventDefault();
  return event.target.form.submit();
});

$(document).on('focus', 'input[id=reference]', function(event) {
  if (event.target.value === 'Référence') {
    return event.target.value = '';
  }
});

$(document).on('blur', 'input[id=reference]', function(event) {
  if (event.target.value === '') {
    return event.target.value = 'Référence';
  }
});

// "Back" links
$(document).on('click', 'a.history-back', function(event) {
  event.preventDefault();
  return window.history.back(-1);
});

$(document).on('mouseover', 'a.history-back', function(event) {
  return event.target.style.cursor = 'pointer';
});

// Kaminari "gap" links
$(document).on('click', 'a.kaminari-gap', function(event) {
  return event.preventDefault();
});

// Campings images must not be downloadable
$(document).on('contextmenu', 'a.img--back', function(event) {
  return event.preventDefault();
});
